import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingIssueService {
    private _dataFilter: any;
    constructor() {}
  
    setCalendarValue(data: any) {
      this._dataFilter = data;
    }
  
    getCalendarValue() {
      return this._dataFilter;
    }
}
