<p-dialog [(visible)]="isShowViewDetails" [draggable]="false" [modal]="true" [dismissableMask]="true" (onHide)="closeViewOriginalFlight()"
  [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
        <div class="!px-4">
            <h1 class="text-lg text-gray-900 font-semibold">{{ 'COMMON.FLIGHT_DETAILS' | translate }}</h1>
            <span class="text-sm text-palette-gray-700">
                {{ 'BOOKING_ISSUE.FLIGHT_UPDATE' | translate: { day: requestDetail.updated_at | dateFormat: true: true } }}
            </span>
        </div>
    </ng-template>
    <div *ngIf="flightDetail && !isLoading" class="flex flex-col gap-y-2">
        <div *ngIf="flightDetail?.flight_info?.itineraries?.length > 1"
            class="mb-2 border border-palette-gray-200 shadow-sm bg-white flex p-[7px] items-center rounded-lg text-sm font-semibold">
            <div (click)="changeViewMode('DEPART')"
                class="min-w-[52px] rounded-lg h-9 flex justify-center items-center px-3 py-2 mr-2 cursor-pointer"
                [ngClass]="currentModeView == 'DEPART'? 'bg-palette-blue-50 text-branding-primary-600' : 'text-palette-gray-500'">
                {{ "FLIGHT.MY_BOOKING.DEPARTURE" | translate}}
            </div>
            <div (click)="changeViewMode('RETURN')"
                [ngClass]="currentModeView== 'RETURN'? 'bg-palette-blue-50 text-branding-primary-600': 'text-palette-gray-500'"
                class="min-w-[52px] h-9 rounded-lg flex justify-center items-center cursor-pointer px-3 py-2">
                {{ "FLIGHT.MY_BOOKING.RETURN" | translate}}
            </div>
        </div>
        <!-- <div class="flex flex-row items-center !gap-2 mb-3 !p-3 border border-palette-blue-600 rounded-xl bg-palette-blue-50">
            <i class="sctr-icon-info-circle text-palette-blue-600 text-xl !leading-5"></i>
            <p class="text-sm font-medium text-palette-gray-700">This booking's flight information has been updated by Soctrip.</p>
        </div> -->
        <!-- booking info -->
        <div class="bg-white rounded-lg !p-4">
            <p class="text-palette-gray-900 text-base font-semibold">{{ 'BOOKING_RESERVATION.BOOKING_INFORMATION' | translate }}</p>
            <div class="!mt-4">
                <div class="flex justify-between flex-wrap !gap-4">
                    <div class="min-w-[240px]">
                        <div class="flex items-center !gap-1">
                            <p class="font-inter text-sm !font-normal text-palette-gray-500">{{ 'FLIGHT.PAYMENT.BOOKING_STATUS' | translate }}</p>
                            <div class="relative hover:cursor-pointer">
                                <i
                                    [position]="TooltipPosition.RIGHT"
                                    [theme]="TooltipTheme.LIGHT"
                                    [tooltip]="'FLIGHT.PAYMENT.BOOKING_STATUS_DESC' | translate"
                                    class="sctr-icon-info-circle text-palette-gray-500 text-base"></i>
                            </div>
                        </div>
                        <span class="px-2 py-0.5 rounded-full text-xs whitespace-nowrap {{colorStatus}}">
                            {{getStatus(flightDetail?.booking_info?.booking_status) | translate}}</span>
                    </div>
                    <div class="min-w-[240px]">
                        <div class="flex items-center !gap-1">
                            <p class="font-inter text-sm !font-normal text-palette-gray-500">{{ 'FLIGHT.PAYMENT.TICKET_STATUS' | translate }}</p>
                            <div class="relative hover:cursor-pointer">
                                <i
                                    [position]="TooltipPosition.RIGHT"
                                    [theme]="TooltipTheme.LIGHT"
                                    [tooltip]="'FLIGHT.PAYMENT.TICKET_STATUS_DESC' | translate" 
                                    class="sctr-icon-info-circle text-palette-gray-500 text-base">
                                </i>
                            </div>
                        </div>
                        <span [ngClass]="loadColorTicketStatus(flightDetail?.booking_info?.ticket_status)" class="px-2 py-0.5 rounded-full text-xs whitespace-nowrap">
                            {{getTicketStatus(flightDetail?.booking_info?.ticket_status) | translate}}</span>
                    </div>
                    <div class="min-w-[240px]">
                        <p class="font-inter text-sm !font-normal text-palette-gray-500">{{ 'FLIGHT.PAYMENT.BOOKING_ID' | translate }}</p>
                        <p class="!mt-1 text-palette-gray-700">{{bookingId}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="!px-4">
            <hr class="border-t-1 border-dashed border-gray-400 !mt-5 !mb-4 text-palette-gray-500">
        </div>
        <div class="!flex flex-col">
            <flight-schedule 
                [bookingInfo]="flightDetail.booking_info"
                [segments]="currentSegments"
                [flightInfo]="flightDetail.flight_info"
                [isHidePNRCode]="false"
                [isHideGDSSource]="false"
                [isHideReferenceIdFailed]="false"
                [segment_type]="currentSegmentType">
            </flight-schedule >

            <!-- Passengers -->
            <app-passengers-info
                class="!mt-2"
                [currentModeView]="currentModeView"
                [passengerList]="flightDetail.passenger_information">
            </app-passengers-info>
        
            <!-- Extra Baggage -->
            <extra-baggage 
                [currentModeView]="currentModeView"
                [segments]="currentSegments"
                [passengerInfo]="flightDetail.passenger_information">
            </extra-baggage>
            
            <!-- Seat -->
            <seat-selection 
                [currentModeView]="currentModeView"
                [segments]="currentSegments"
                [passengerInfo]="flightDetail.passenger_information">
            </seat-selection>
            
            <!-- Meal -->
            <meal-selection 
                [currentModeView]="currentModeView"
                [segments]="currentSegments"
                [passengerInfo]="flightDetail.passenger_information">
            </meal-selection>

            <!-- Fare rule -->
            <fare-rule [fareRuleVi]="fareRuleVi" [resource]="resource"
              [fareRule]="currentFareRule" [currentSegments]="currentSegments"></fare-rule>
        </div>
    </div>
    <div *ngIf="!flightDetail && isLoading">
        <div>
            <div class="flex space-x-0 lg:!space-x-6 w-full">
                <div class="w-full">
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="!pt-4">
            <button
                class="!h-11 !min-w-[88px] px-2 border border-palette-gray-300 
                    text-palette-gray-900 font-semibold rounded-lg hover:bg-palette-gray-200 
                    focus:outline-none focus:ring-2 focus:ring-palette-gray-300 focus:ring-opacity-50"
                (click)="closeViewOriginalFlight()">
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
    </ng-template>
</p-dialog>