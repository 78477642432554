<div class="w-full h-full font-inter">
    <div class="w-[100%] rounded-xl border border-palette-gray-200 bg-white">
        <div class="p-3">
            <div class="flex justify-between items-center cursor-pointer" 
                (click)="handleClickExpandCollapse()"
                (mouseover)="isMouseOverExpand=true"
                (mouseleave)="isMouseOverExpand=false">
                <div class="flex items-center">
                    <span [ngClass]="{'!font-extrabold':isMouseOverExpand}"
                        class="ml-2 text-lg font-semibold text-palette-gray-900">{{"FLIGHT.PASSENGERS.PASSENGERS" |
                        translate}}</span>
                    <div @slideInOut *ngIf="!isExpand" class="flex items-center ml-2">
                        <span *ngIf="totalAdults > 0"> ({{totalAdults}} {{'FLIGHT.PASSENGERS.ADULT'| translate: {syntax:
                            totalAdults >= 2 ? "s" : ""} | lowercase}}{{(totalChildren == 0 && totalInfant == 0) ? ")" : ""}}</span>
                        <span *ngIf="totalChildren > 0">, {{totalChildren}} {{'FLIGHT.PASSENGERS.CHILD'| translate:
                            {syntax: totalChildren >= 2 ? "s" : ""} | lowercase}}{{(totalInfant == 0) ? ")" : ""}}</span>
                        <span *ngIf="totalInfant > 0">, {{totalInfant}} {{'FLIGHT.PASSENGERS.INFANT'| translate: {syntax:
                            totalInfant >= 2 ? "s" : ""} | lowercase}})</span>
                    </div>
                    
                </div>
                <i [ngClass]="{'sctr-icon-chevron-down':isExpand, 'sctr-icon-chevron-right':!isExpand, 'font-extrabold':isMouseOverExpand}" class="text-xl leading-5 text-palette-gray-500"></i>
            </div>

            <div @slideDownUp *ngIf="isExpand" class="!gap-4 p-2 grid m-0 !mb-2" 
                [ngClass]="passengerList.length > 1 ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2' : 'grid-cols-1'">
                <div *ngFor="let item of passengerList; let i = index"
                    class="col-span-1 rounded-lg border border-palette-gray-200 bg-palette-gray-50 p-3 max-[767px]:mt-3"
                    [ngClass]="{'border-palette-red-200 bg-palette-red-50': item.passenger_type.toUpperCase() === 'INFANT'}">
                    <div class="flex justify-between">
                        <span class="!mb-3 text-sm font-semibold text-palette-gray-900">
                            {{
                                "FLIGHT.PASSENGERS." + item.passenger_type
                                | uppercase
                                | translate : { syntax: "" }
                                | titlecase
                            }}
                        </span>
                        <span *ngIf="isModeBookingIssue" class="!h-5 !py-0.5 !px-1 bg-[#FEF3F2] border text-[#D92D20] border-[#D92D20] rounded-full text-xs !font-medium">{{ 'BOOKING_ISSUE.CANCELED' | translate }}</span>
                    </div>
                    <div class="flex flex-col !gap-3">
                        <!-- Full Name -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700 whitespace-nowrap">
                                {{ "FLIGHT.PASSENGERS.FULL_NAME" | translate }}
                            </div>
                            <div class="flex-1 relative flex justify-end">
                                <div class="line-clamp-1 text-sm font-medium text-palette-gray-900"
                                    [isTruncated]="true"
                                    [tooltip]="('COMMON.' + item?.title?.toUpperCase() | translate | uppercase) + ' ' + item?.last_name?.toUpperCase() + ' ' + item?.middle_and_first_name?.toUpperCase()">
                                    {{ 'COMMON.' + item.title?.toUpperCase() | translate | uppercase }} {{ item.last_name | uppercase }} {{ item.middle_and_first_name | uppercase }}
                                </div>
                            </div>
                        </div>
                        <!-- Nationality -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700">
                                {{ "FLIGHT.PASSENGERS.NATIONALITY" | translate }}
                            </div>
                            <div class="relative flex justify-end">
                                <div class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                    [isTruncated]="true" [tooltip]="item.nationality">
                                    {{ item.nationality }}
                                </div>
                            </div>
                        </div>
                        <!-- Date of birth -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700">
                                {{ "FLIGHT.PASSENGERS.DATE_OF_BIRTH" | translate }}
                            </div>
                            <div class="truncate text-end text-sm font-medium text-palette-gray-900">
                                {{ item.date_of_birth | dateFormat }}
                            </div>
                        </div>
                        <!-- Passport -->
                        <ng-container *ngIf="item.passport_identity">
                            <div class="flex justify-between items-center !gap-4">
                                <div class="text-sm font-normal text-palette-gray-700">
                                    {{ "FLIGHT.PAYMENT.PASSPORT" | translate }}
                                </div>
                                <div class="relative flex justify-end">
                                    <div class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                        [isTruncated]="true" [tooltip]="item.country + ' • ' + item.passport_identity">
                                        {{ item.country ?? item.nationality }} • {{ item.passport_identity }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item.passport_expiry_date" class="flex justify-between items-center !gap-4">
                                <div class="text-sm font-normal text-palette-gray-700">
                                    {{ "FLIGHT.PAYMENT.PASSPORT_EXPIRY" | translate }}
                                </div>
                                <div class="truncate text-end text-sm font-medium text-palette-gray-900">
                                    {{ item.passport_expiry_date | dateFormat}}
                                </div>
                            </div>
                        </ng-container>
                        <!-- Ticket number -->
                        <div *ngIf="(currentModeView ==='DEPART' && item?.depart_ticket_number) || 
                            (currentModeView ==='RETURN' && item?.return_ticket_number) || item?.ticket_number" 
                            class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700">
                                {{ "FLIGHT.PAYMENT.TICKET_NUMBER" | translate }}
                            </div>
                            <div class="relative flex justify-end">
                                <div *ngIf="item?.ticket_number" 
                                    class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                    [isTruncated]="true" [tooltip]="item?.ticket_number">
                                    {{ item?.ticket_number }}
                                </div>
                                <div *ngIf="!item?.ticket_number" 
                                    class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                    [isTruncated]="true" [tooltip]="item.depart_ticket_number">
                                    {{ currentModeView ==='DEPART' ? item?.depart_ticket_number : item?.return_ticket_number }}
                                </div>
                            </div>
                        </div>
                        <button
                            *ngIf="isModeBookingIssue"
                            (click)="onShowChooseSchedulePopup()"
                            class="w-full rounded-lg hover:bg-[#EFF8FF] !py-1 !px-2 border 
                            border-[#1570EF] text-center text-sm !font-semibold  text-[#1570EF]">
                            {{ 'FEE.VIEW_DETAILS' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Popup Choose a schedule -->
<p-dialog [(visible)]="isShowChooseSchedulePopup" [draggable]="false" [modal]="true" [dismissableMask]="true" (onHide)="onCloseChooseSchedulePopup()"
  [style]="{ width: '400px' }">
    <ng-template pTemplate="header">
        <h1 class="text-lg text-gray-900 font-semibold">{{ 'BOOKING_ISSUE.CHOOSE_SCHEDULE' | translate }}</h1>
    </ng-template>
    <div *ngIf="true" class="flex flex-col gap-y-2 admin-custom">
        <div class="!flex flex-col !gap-y-3 custom-radio">
            <div *ngFor="let trip of listHistoryAction" class="!py-3 !px-4 flex !gap-3 justify-items-center border rounded-lg">
                <p-radioButton [inputId]="trip.id" name="category" (ngModelChange)="onChange()" [value]="trip" [(ngModel)]="selectedHistoryAction"></p-radioButton>
                <div class="w-full">
                    <div>
                        <span class="font-inter text-sm !font-medium text-palette-gray-900">{{ trip.trip }}</span>
                         | 
                        <span class="font-inter text-sm !font-medium text-palette-gray-900">{{ trip.id }}</span>
                    </div>
                    <div class="flex justify-between">
                        <span class="font-inter text-xs !font-normal text-palette-gray-500">{{ 'BOOKING_ISSUE.UPDATE' | translate }}: {{ trip.date | dateFormat: true }}</span>
                        <span class="!px-2 !py-0.5 bg-[#EDFCF2] text-palette-green-600 rounded-full text-xs !font-medium">{{ 'BOOKING_ISSUE.RESCHEDULED_STATUS' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="">
        <div>
            <div class="flex space-x-0 lg:!space-x-6 w-full">
                <div class="w-full">
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div>
            <button
                class="!h-11 !min-w-[88px] px-2 border border-palette-gray-300 
                    text-palette-gray-900 font-semibold rounded-lg hover:bg-palette-gray-200 
                    focus:outline-none focus:ring-2 focus:ring-palette-gray-300 focus:ring-opacity-50"
                (click)="onCloseChooseSchedulePopup()">
                {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button (click)="handleViewDetails('SOCTRIP-1102345604', null)" class="rounded-lg bg-palette-blue-600 hover:!bg-palette-blue-500 text-white text-sm font-semibold !px-5 !py-3 transition-all">
                {{ 'COMMON.CONFIRM' | translate }}
            </button>
        </div>
    </ng-template>
</p-dialog>

<app-view-details-flight
    [id]="viewDetailID"
    [requestDetail]="requestDetail"
    [(isShowViewDetails)]="isShowViewDetails">
</app-view-details-flight>