<div *ngIf="currency?.currency!==this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'"
    class="w-full min-w-[300px] h-auto rounded-lg bg-palette-blue-50 border-1 border-palette-blue-600 !mb-2 !p-4">
    <p class="font-inter text-base font-semibold text-palette-gray-900">{{'FLIGHT.PAYMENT.CURRENCY_EXCHANGE' | translate}}</p>
    <div class="list-disc !ms-2">
        <li class="font-inter text-sm font-normal text-palette-gray-900">{{'FLIGHT.PAYMENT.YOU_HAVE_CHOSEN_PAY_BOOKING' |
            translate: { currencyExchangeRate: flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() } }}</li>
        <li class="font-inter text-sm font-normal text-palette-gray-900">{{'FLIGHT.PAYMENT.THE_AMOUNT_SHOWN' | translate: {
            selectedCurrency: currency?.currency, currencyExchangeRate:
            flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() } }}</li>
    </div>
</div>

<div class="my-price-details-section w-full min-[1100px]:w-[384px] bg-palette-base-white border border-palette-gray-200 rounded-lg">
    <p class="!pt-4 !px-4 text-palette-gray-900 font-inter text-base font-semibold leading-6">
        {{'MY_FLIGHT_BOOKING_DETAIL.PRICE_DETAILS.TITLE' | translate}}
    </p>

    <!-- PRICE -->
    <ng-container *ngFor="let price of flightDetail?.price_details?.price_details; let i = index">
        <div class="!px-4 !pt-4 font-inter w-full">
            <div class="border-b border-b-palette-gray-200 !pb-4 flex flex-col !gap-2">
                <div (click)="toggleCollapse(i)" class="flex items-center justify-between custom-bg h-[21px] !px-2 pt-[3px] cursor-pointer">
                    <p class="text-xs font-semibold text-palette-gray-900 uppercase">
                        {{(price.fare_type==='departure' ? 'FLIGHT.PAYMENT.DEPART' :
                        'FLIGHT.PAYMENT.RETURN') | translate}}
                    </p>
                    <div class="flex items-center gap-x-2">
                        <p class="text-sm font-medium text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originCurrency; else exchangeCurrency"></span>
                        <ng-template #originCurrency>
                            <!-- <span>{{transformCurrency()}}</span> -->
                            {{ price.final_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeCurrency>
                            <app-price-format
                                [price]="price.final_price">
                            </app-price-format>
                        </ng-template>
                        </p>
                        <span class="!w-4 !h-4 flex justify-center items-center">
                    
                            <i class="text-base leading-4 {{collapsedStates[i] ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="!collapsedStates[i]" class="flex flex-col !gap-2 font-inter text-sm">
                    <div class="font-semibold text-palette-gray-900">
                        {{'FLIGHT.PAYMENT.PRICE_DETAILS' | translate}}
                    </div>
                    <div *ngIf="price.num_of_adults" class="flex items-center justify-between">
                        <p class="font-normal text-palette-gray-700 first-letter:uppercase">
                            <span>{{'COMMON.ADULT' | translate:{ syntax: price.num_of_adults > 1
                                ? 's': '' } }}</span> x {{ price.num_of_adults }}
                        </p>
                        <p class="font-medium text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originFareCurrency; else exchangeFareCurrency"></span>
                        <ng-template #originFareCurrency>
                            <!-- <span>{{transformCurrency(price.adult_fare_price)}}</span> -->
                            {{ price.adult_fare_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeFareCurrency>
                            <app-price-format [price]="price.adult_fare_price"></app-price-format>
                        </ng-template>
                        </p>
                    </div>
                    <div *ngIf="price.num_of_children > 0" class="flex items-center justify-between">
                        <p class="font-normal text-palette-gray-700 first-letter:uppercase">
                            <span>{{(price.num_of_children>1?'COMMON.CHILDREN':'COMMON.CHILD') |
                                translate}}</span> x {{ price.num_of_children }}
                        </p>
                        <p class="font-medium text-palette-gray-900">
                            <span
                                *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originChildCurrency; else exchangeChildCurrency"></span>
                            <ng-template #originChildCurrency>
                                <!-- <span>{{transformCurrency(price.child_fare_price)}}</span> -->
                                {{ price.child_fare_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                            </ng-template>
                            <ng-template #exchangeChildCurrency>
                                <app-price-format [price]="price.child_fare_price"></app-price-format>
                            </ng-template>
                        </p>
                    </div>
                    <div *ngIf="price.num_of_infants > 0" class="flex items-center justify-between">
                        <p class="font-normal text-palette-gray-700 first-letter:uppercase">{{'COMMON.INFANT' | translate }} x {{
                            price.num_of_infants }}</p>
                        <p class="font-medium text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originInfantCurrency; else exchangeInfantCurrency"></span>
                        <ng-template #originInfantCurrency>
                            <!-- <span>{{transformCurrency(price.infant_fare_price)}}</span> -->
                            {{ price.infant_fare_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeInfantCurrency>
                            <app-price-format [price]="price.infant_fare_price"></app-price-format>
                        </ng-template>
                        </p>
                    </div>
                    <div class="flex items-center justify-between">
                        <p class="font-normal text-palette-gray-700">{{ 'COMMON.TAXES_AND_CHARGES' | translate}}</p>
                        <p class="font-medium text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originChargesCurrency; else exchangeChargesCurrency"></span>
                        <ng-template #originChargesCurrency>
                            <!-- <span>{{transformCurrency(price.taxes_charges)}}</span> -->
                            {{ price.taxes_charges | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeChargesCurrency>
                            <app-price-format [price]="price.taxes_charges"></app-price-format>
                        </ng-template>

                        </p>
                    </div>
                </div>
                <div *ngIf="!collapsedStates[i] && (serviceData[i].numSeat || serviceData[i].numMeal || serviceData[i]?.numExtraBaggage)"
                    class="!pt-2 flex flex-col !gap-2 border-t border-dashed border-palette-gray-200">
                    <p class="text-sm font-semibold text-palette-gray-900">
                        {{'FLIGHT.PAYMENT.SERVICES' | translate}}
                    </p>
                    <div *ngIf="serviceData[i].numExtraBaggage" class="flex justify-between">
                        <p class="text-sm text-palette-gray-700">{{'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate}} x {{ serviceData[i].numExtraBaggage }}</p>
                        <p class="text-sm font-medium text-palette-amber-600">
                                <span
                                *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originBaggageCurrency; else exchangeBaggageCurrency"></span>
                            <ng-template #originBaggageCurrency>
                                <!-- <span>{{transformCurrency(serviceData[i].totalExtraBaggage)}}</span> -->
                                {{ serviceData[i].totalExtraBaggage | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                            </ng-template>
                            <ng-template #exchangeBaggageCurrency>
                                <app-price-format class="text-sm font-medium text-palette-amber-600"
                                    [price]="serviceData[i].totalExtraBaggage"></app-price-format>
                            </ng-template>
                        </p>
                    </div>
                    <div *ngIf="serviceData[i].numSeat" class="flex justify-between">
                        <p class="text-sm text-palette-gray-700">{{'FLIGHT.PAYMENT.SEAT_SELECTION' | translate}} x {{ serviceData[i].numSeat }}</p>
                        <p class="text-sm font-medium text-palette-amber-600">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originSeatCurrency; else exchangeSeatCurrency"></span>
                        <ng-template #originSeatCurrency >
                            <!-- <span>{{transformCurrency(serviceData[i].totalSeat)}}</span> -->
                            {{ serviceData[i].totalSeat | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeSeatCurrency >
                            <app-price-format class="text-sm font-medium text-palette-amber-600"
                                [price]="serviceData[i].totalSeat"></app-price-format>
                        </ng-template>
                        </p>
                    </div>
                    <div *ngIf="serviceData[i].numMeal" class="font-inter">
                        <div class="flex flex-col !gap-1">
                            <div class="flex justify-between !gap-4">
                                <p class="text-sm font-normal text-palette-gray-700">{{'FLIGHT.PAYMENT.MEAL' | translate}} x {{serviceData[i].numMeal}}</p>
                                <span
                                    *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originMealCurrency; else exchangeMealCurrency"></span>
                                <ng-template #originMealCurrency>
                                    <!-- <span>{{transformCurrency(serviceData[i].totalMeal)}}</span> -->
                                    {{ serviceData[i].totalMeal | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                                </ng-template>
                                <ng-template #exchangeMealCurrency>
                                    <app-price-format
                                        class="text-sm font-medium text-palette-amber-600 whitespace-nowrap"
                                        [price]="serviceData[i].totalMeal"> </app-price-format>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Voucher -->
    <div *ngIf="flightDetail?.price_details?.vouchers?.length" class="!px-4 !pt-4">
        <p class="text-sm font-semibold text-palette-gray-900 capitalize !mb-2">
            {{'FLIGHT.PAYMENT.VOUCHER' | translate}}
        </p>
        <div class="flex flex-col !gap-1">
            <div *ngFor="let item of flightDetail?.price_details?.vouchers">
                <div class="flex justify-between items-center">
                    <div
                        class="flex items-center gap-1 bg-palette-emerald-50 !pr-2 !pl-[6px] !py-[2px] rounded-2xl">
                        <img class="w-[12px] h-[12px]" src="assets/images/flight-view-details/checked-voucher.svg"
                            alt="">
                        <span
                            class="text-system-success-700 font-inter text-xs leading-[18px] font-medium uppercase">{{item.voucher_code}}</span>
                    </div>
                    <div class="flex items-center">
                        <span class="mr-[4px] text-xs !leading-[18px] font-semibold text-palette-gray-900"> - </span>
                        <div class="font-inter text-sm font-semibold text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originDiscountCurrency; else exchangeDiscountCurrency"></span>
                        <ng-template #originDiscountCurrency>
                            <!-- <span>{{transformCurrency(item.discount_price)}}</span> -->
                            {{ item.discount_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeDiscountCurrency>
                            <app-price-format [price]="item.discount_price"></app-price-format>
                        </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Total -->
    <div class="!p-4 flex flex-col !gap-1">
        <ng-container *ngIf="currency?.currency===this.flightDetail.exchange_rate.currency_exchange_rate.toUpperCase() && 
            flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; else exchangeTotalCurrency">
            <div class="flex justify-between items-center">
                <h4 class="text-palette-gray-900 font-inter text-base font-semibold leading-6"> {{"COMMON.TOTAL" | translate}} </h4>
                <span class="text-palette-orange-dark-600 font-inter text-xl font-semibold leading-[30px]">
                    {{ flightDetail?.price_details.total_price | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                </span>
            </div>
            <div class="text-palette-gray-600 text-sm text-end">
                ({{ 'FLIGHT.PAYMENT.INCLUDED' | translate}} 
                <span class="text-palette-gray-900 font-semibold">
                    {{ flightDetail?.price_details.taxes_and_fees | historyCurrency: flightDetail.exchange_rate.exchange_rate_value : flightDetail.exchange_rate.currency_exchange_rate : currency?.currency }}
                </span> 
                {{ 'FLIGHT.PAYMENT.TAXES_AND_FEES' | translate}})
            </div>
        </ng-container>
        <ng-template #exchangeTotalCurrency>
            <div class="flex justify-between items-center">
                <h4 class="text-palette-gray-900 font-inter text-base font-semibold leading-6"> {{"COMMON.TOTAL" | translate}} </h4>
                <span class="text-palette-orange-dark-600 font-inter text-xl font-semibold leading-[30px]">
                    <app-price-format [price]="flightDetail?.price_details.total_price"></app-price-format>
                </span>
            </div>
            <div class="text-palette-gray-600 text-sm text-end">
                ({{ 'FLIGHT.PAYMENT.INCLUDED' | translate}} 
                <span class="text-palette-gray-900 font-semibold whitespace-nowrap">
                    <app-price-format [price]="flightDetail?.price_details?.taxes_and_fees"></app-price-format>
                </span> 
                {{ 'FLIGHT.PAYMENT.TAXES_AND_FEES' | translate}})
            </div>
        </ng-template>
    </div>

    <!-- Buttons -->
    <!-- <div class="!p-4 flex flex-col !gap-3 border-t-[1px] border-palette-gray-200">
        <button
            class="h-[44px] bg-palette-base-white hover:bg-palette-gray-50 border border-palette-gray-300 rounded-lg">
            <common-icon-text class="flex items-center gap-2 justify-center" [iconStyle]="'sctr-icon-file-x-02'"
                (click)="showCancelPopup()"
                [icon]="'text-xl font-medium text-palette-gray-900'"
                [text]="'MY_FLIGHT_BOOKING_DETAIL.CANCEL_BOOKING' | translate"
                [textStyle]="'font-inter text-palette-gray-900 text-sm font-semibold leading-5'">
            </common-icon-text>
        </button>
        <button class="h-[44px] bg-palette-base-white hover:bg-palette-gray-50 border border-palette-gray-300 rounded-lg">
            <common-icon-text 
                (click)="showReschedulePopup()"
                class="flex items-center gap-2 justify-center"
                [iconStyle]="'sctr-icon-calendar'"
                [icon]="'text-xl font-medium text-palette-gray-900'"
                [text]="'MY_FLIGHT_BOOKING_DETAIL.REQUEST_RESCHEDULE' | translate"
                [textStyle]="'font-inter text-palette-gray-900 text-sm font-semibold leading-5'">
            </common-icon-text>
        </button>
    </div> -->
</div>
<app-cancel-booking class="fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden"
    *ngIf="isShowCancelPopup"
    (closePopup)="closeCancelPopup()"></app-cancel-booking>
<app-reschedule-booking *ngIf="isShowReschedulePopup" (close)="closeReschedulePopup()"></app-reschedule-booking>

