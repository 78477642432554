import { Component, Input } from '@angular/core';
import { TICKET_STATUS } from '@shared/enum/booking.enum';

interface IBookingInformation {
  referenceId: string;
  bookingId: string;
  pinCode: string;
  bookingDate: string;
  paymentMethod: string;
  paymentStatus: string;
  bookingStatus: string;
}
enum BOOKING_STATUS {
  CONFIRMED = 'Confirmed',
  CANCELED = 'Canceled',
  REFUNDED = 'Refunded',
  PROCESSING = 'Processing',
  PAYMENT_FAILED = 'Payment failed',
  RESCHEDULED = 'Rescheduled',
  PENDING_REFUND = 'Pending refund',
  REFUND_FAILED = 'Refund failed',
  PENDING_PAYMENT = 'Pending payment',
}

@Component({
  selector: 'booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent {
  @Input() flightDetail: any;
  @Input() currentModeView: any;
  isShowPinCode: boolean = false;

  ngOnInit(): void {}


  handleClassBookingStatus(status: string) {
    switch (status) {
      case BOOKING_STATUS.CONFIRMED:
      case BOOKING_STATUS.RESCHEDULED:
        return 'bg-palette-emerald-50 text-palette-emerald-600';
      case BOOKING_STATUS.CANCELED:
      case BOOKING_STATUS.REFUNDED:
      case BOOKING_STATUS.PENDING_REFUND:
        return 'bg-palette-gray-100 text-palette-gray-700';
      case BOOKING_STATUS.PROCESSING:
        return 'bg-palette-amber-50 text-palette-amber-700';
      case BOOKING_STATUS.REFUND_FAILED:
      case BOOKING_STATUS.PAYMENT_FAILED:
        return 'bg-palette-red-50 text-palette-red-600';
      case BOOKING_STATUS.PENDING_PAYMENT:
          return 'text-palette-orange-600 bg-palette-orange-50';
      default:
        return '';
    }
  }

  handleColorTicketStatus(status: string) {
    if(status){
      const ticketStatus = status?.toUpperCase();
      switch (ticketStatus) {
          case 'HOLD':
              return 'text-palette-yellow-600 bg-palette-yellow-50';
          case 'EXPIRED':
              return 'bg-palette-red-50 text-palette-red-600';
          case 'TICKETING':
              return 'text-palette-blue-700 bg-palette-blue-50';
          case "TICKETED":
              return 'bg-palette-green-50 text-palette-green-600';
          default:
              return 'text-palette-red-600 bg-palette-red-50';
      }
    }
    return ''
   
}

  getStatus(status: string): string {
    switch (status) {
        case "Pending booking":
          return "BOOKING_HELPDESK.PENDING_BOOKING";
        case "Confirmed":
            return "BOOKING_HELPDESK.CONFIRMED";
        case "Pending confirmation":
            return "BOOKING_HELPDESK.PENDING_CONFIRMATION";
        case "Rescheduled":
          return "BOOKING_HELPDESK.RESCHEDULED";
        case "Processing":
          return "BOOKING_HELPDESK.PROCESSING";
        case "In progress":
            return "BOOKING_HELPDESK.IN_PROGRESS";
        case "Pending review":
            return "BOOKING_HELPDESK.PENDING_REVIEW";
        case "Completed":
            return "BOOKING_HELPDESK.COMPLETED";
        case "Pending refund":
            return "BOOKING_HELPDESK.PENDING_REFUND";
        case "Refunded":
            return "BOOKING_HELPDESK.REFUNDED";
        case "Refunded failed":
          return "BOOKING_HELPDESK.REFUND_FAILED";
        case "Canceled":
            return "BOOKING_HELPDESK.CANCELED";
        case "Payment failed":
            return "BOOKING_HELPDESK.PAYMENT_FAILED";
        case "Pending payment":
            return "BOOKING_HELPDESK.PENDING_PAYMENT";
        default:
            return status;
    }
  }

  getTicketStatus(status: string): string {
    switch (status) {
        case TICKET_STATUS.HOLD:
            return "COMMON.TICKET_STATUS.HOLD";
        case TICKET_STATUS.EXPIRED:
            return "COMMON.TICKET_STATUS.EXPIRED";
        case TICKET_STATUS.TICKETED:
            return "COMMON.TICKET_STATUS.TICKETED";
        case TICKET_STATUS.TICKETING:
            return "COMMON.TICKET_STATUS.TICKETING";
        default:
            return status;
    }
  }

  hidePinCode(pinCode: string) {
    return pinCode?.replace(/./g, '•');
  }

  togglePinCode() {
    this.isShowPinCode = !this.isShowPinCode;
  }
  
  getSourceOrderId(sourceOrderId: any){
    if(sourceOrderId && sourceOrderId.toUpperCase() != 'FAILED'){
      return sourceOrderId
    }
    return ""
  }
}
