import { Component, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-passengers-info',
  templateUrl: './passengers-info.component.html',
  styleUrls: ['./passengers-info.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition(':enter', [animate('300ms ease-out')]),
      transition(':leave', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms ease-in')]),
      transition(':leave', [animate('300ms ease-out')]),
    ]),
  ],
})
export class PassengersInfoComponent {
  @Input() passengerList: any[];
  @Input() currentModeView: string;
  @Input() isModeBookingIssue: boolean = false;
  
  isExpand: boolean = false;
  isMouseOverExpand: boolean = false;
  totalAdults: number = 0;
  totalChildren: number = 0;
  totalInfant: number = 0;
  isShowChooseSchedulePopup = false;
  listHistoryAction: any = [];
  selectedHistoryAction: any = null
  isShowViewDetails: boolean = false;
  requestDetail: any;
  viewDetailID: any

  ngOnInit() {
    this.listHistoryAction = [
      {
        id: 'SB012564852 - 1',
        trip: 'SGN -> HKG',
        date: new Date()
      },
      {
        id: 'SB012564852 - 2',
        trip: 'SGN -> HKG',
        date: new Date()
      }
    ]
    this.initTotalData();
  }

  initTotalData() {
    this.totalAdults = this.countPassengerByType(this.passengerList, ['ADULT', 'ADT']);
    this.totalChildren = this.countPassengerByType(this.passengerList, ['CHILD', 'CHD', 'CHILDREN']);
    this.totalInfant = this.countPassengerByType(this.passengerList, ['INFANT', 'INF ']);
  }
  
  countPassengerByType(passengerList: any[], types: string[]) {
    return passengerList.filter(
      (passenger) => types.includes(passenger.passenger_type.toUpperCase())
    ).length;
  }

  handleClickExpandCollapse() {
    this.isExpand = !this.isExpand;
  }

  onCloseChooseSchedulePopup() {
    this.isShowChooseSchedulePopup = false;
  }

  onShowChooseSchedulePopup() {
    this.isShowChooseSchedulePopup = true;
  }

  onChange() {

  }

  handleViewDetails(viewDetailID: string, requestDetail: any) {
    this.requestDetail = {
      "action_type": "CANCEL_BOOKING",
      "issue_status": "IN_PROGRESS",
      "request_id": null,
      "requested_at": "2025-02-11T11:04:15.363949558",
      "issue_summary": "Hủy chuyến",
      "updated_at": "2025-02-11T11:04:15.363907205",
      "handler_name": "Ming Ming",
      "solution_note": "Chấp nhận",
      "customer_note": "Thay đổi kế hoạch du lịch",
      "refund_amount": 12,
      "agency_deduct_amount": 5,
      "passenger_ids": [
        [
          1
        ]
      ],
      "action_text": "This booking has been canceled for the selected passengers and sent to the Refund Management to process the refund.",
      "admin_attachments": null,
      "user_attachments": null,
      "canceled_list": [
        [
          {
            "passenger_id": 1,
            "passenger_type": "Adult",
            "title": "MR",
            "last_name": "PHAM",
            "middle_and_first_name": "ANH",
            "phone": "8426394730",
            "date_of_birth": "1997-05-04",
            "nationality": "Vietnam",
            "country": "Vietnam",
            "passport_identity": "424552",
            "passport_expiry_date": "2040-12-12",
            "frequent_flyer_program": null,
            "frequent_flyer_number": null,
            "extra_baggage_list": null,
            "passenger_seat_list": null,
            "extra_meal_list": null,
            "ticket_number": "6190",
            "canceled": "SOCTRIP-1102345604",
            "reschedule": null
          }
        ]
      ],
      "pay_more": false,
      "view_detail": "SOCTRIP-1102345604"
    };
    this.viewDetailID = viewDetailID;
    this.isShowViewDetails = true;
  }
}
