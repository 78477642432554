import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightBookingIssueControllerService } from '@booking/angular-booking-admin-service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { BookingIssueService } from 'src/app/services/booking-issue/booking-issue.service';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';

export enum TICKET_STATUS {
  HOLD = 'Hold',
  EXPIRED = 'Expired',
  TICKETING = 'Ticketing',
  TICKETED = 'Ticketed'
}

@Component({
  selector: 'app-view-details-flight',
  templateUrl: './view-details-flight.component.html',
  styleUrls: ['./view-details-flight.component.scss']
})
export class ViewDetailsFlightComponent {
  @Input() requestDetail: any;
  @Input() id: any = '';
  @Input() isShowViewDetails: boolean = false;
  @Output() isShowViewDetailsChange = new EventEmitter<boolean>();
  @Output() dataEmitter: EventEmitter<any> = new EventEmitter();

  querySubscription: Subscription;
  bookingId: string;
  pinCode: string;
  isLoading: boolean = true;
  onDestroy$: Subject<void> = new Subject();
  pageNum: number;
  pageSize: number;
  isClickBack: boolean = false;
  isShowChangeRequestHistoryPopup: boolean = false;
  changeRequestHistory: any;
  currentModeView = 'DEPART';
  currentSegments: any;
  currentSegmentType: string;
  currentFareRule: any;
  fareRuleVi: string;
  resource: string;
  flightDetail: any;
  colorStatus: string = '';
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;

  // @Output() isShowPreviewModeChange = new EventEmitter<boolean>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookingIssueService: BookingIssueService,
    private translateService: TranslateService,
    private flightBookingIssueControllerService: FlightBookingIssueControllerService
  ) { }

  ngOnInit() {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'] && changes['id'].currentValue) {
      this.flightDetail = null;
      this.isLoading = true;
      this.getViewDetailsFlightData();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (!this.isClickBack) {
      this.bookingIssueService.setCalendarValue(null);
    }
  }

  loadColorStatus(status: string) {
    const bookingStatus = status.toUpperCase();
    switch (bookingStatus) {
        case 'CONFIRMED':
        case 'RESCHEDULED':
            return 'text-palette-emerald-600 bg-palette-emerald-50';
        case 'PROCESSING':
            return 'text-palette-amber-600 bg-palette-amber-50';
        case 'CANCELED':
        case 'PENDING REFUND':
        case 'REFUNDED':
            return 'text-palette-gray-700 bg-palette-gray-100';
        case "PAYMENT FAILED":
            return 'bg-palette-red-50 text-palette-red-600';
        case "PENDING PAYMENT":
            return 'text-palette-orange-600 bg-palette-orange-50';
        case "PENDING BOOKING":
            return 'text-palette-orange-600 bg-palette-orange-50'
        default:
            return 'text-palette-red-600 bg-palette-red-50';
    }
  }

  getStatus(status: string): string {
    switch (status) {
        case "Pending booking":
            return "COMMON.PENDING_BOOKING";
        case "Confirmed":
            return "COMMON.CONFIRMED";
        case "Rescheduled":
            return "COMMON.RESCHEDULED";
        case "Processing":
            return "COMMON.PROCESSING";
        case "Completed":
            return "COMMON.COMPLETED";
        case "Pending refund":
            return "COMMON.PENDING_REFUND";
        case "Refunded":
            return "COMMON.REFUNDED";
        case "Refund failed":
            return "COMMON.REFUND_FAILED";
        case "Canceled":
            return "COMMON.CANCELED";
        case "Payment failed":
            return "COMMON.PAYMENT_FAILED";
        case "Pending payment":
            return "COMMON.PENDING_PAYMENT";
        default:
            return status;
    }
  }

  loadColorTicketStatus(status: string) {
    const ticketStatus = status?.toUpperCase();
    switch (ticketStatus) {
        case 'HOLD':
            return 'text-palette-yellow-600 bg-palette-yellow-50';
        case 'EXPIRED':
            return 'bg-palette-red-50 text-palette-red-600';
        case 'TICKETING':
            return 'text-palette-blue-700 bg-palette-blue-50';
        case "TICKETED":
            return 'bg-palette-green-50 text-palette-green-600';
        default:
            return 'text-palette-red-600 bg-palette-red-50';
    }
  }

  getTicketStatus(status: string): string {
    switch (status) {
        case TICKET_STATUS.HOLD:
            return "COMMON.TICKET_STATUS.HOLD";
        case TICKET_STATUS.EXPIRED:
            return "COMMON.TICKET_STATUS.EXPIRED";
        case TICKET_STATUS.TICKETED:
            return "COMMON.TICKET_STATUS.TICKETED";
        case TICKET_STATUS.TICKETING:
            return "COMMON.TICKET_STATUS.TICKETING";
        default:
            return status;
    }
  }

  getViewDetailsFlightData() {
    this.flightBookingIssueControllerService
      .viewReservationDetails(this.id)
      .subscribe({
          next: (res: any) => {
              if(res.success) {
                  this.flightDetail = res.data;
                  this.colorStatus = this.loadColorStatus(this.flightDetail?.booking_info?.booking_status);
                  const index = this.currentModeView === 'DEPART' ? 0 : 1;
                  const itinerary = this.flightDetail.flight_info?.itineraries[index];
                  if (itinerary) {
                    this.currentSegments = itinerary.segments;
                    this.currentSegmentType = itinerary.segment_type;
                    this.currentFareRule = itinerary.fare_rule;
                    this.fareRuleVi = itinerary.fare_rule_vi;
                    this.resource = itinerary.resource
                  }
                  this.bookingId = this.flightDetail?.booking_info.reservation_code;
                  this.isLoading = false;
              } else {
                this.isLoading = false;
              }
          },
          error: () => {
            this.isLoading = false;
          }
      });
  }

  initData() {
    const pageNum = this.route.snapshot.queryParamMap.get('page-num');
    const pageSize = this.route.snapshot.queryParamMap.get('page-size');
    this.pageNum = pageNum ? +pageNum : 1;
    this.pageSize = pageSize ? +pageSize : 10;

    this.changeRequestHistory = [
      {
        title: 'RESCHEDULE_FLIGHT',
        detail: 'test 123',
        requested_at: '2024-06-21T10:59:47.569049897',
        status: 'Solved',
        id: '123',
      },
      {
        title: 'RESCHEDULE_FLIGHT',
        detail: 'test 456',
        requested_at: '2024-06-21T11:00:05.906078408',
        status: 'Open',
        id: '456',
      },
      {
        title: 'RESCHEDULE_FLIGHT',
        detail: 'ádđssss',
        requested_at: '2024-06-21T11:00:53.668189571',
        status: 'Open',
        id: '789',
      },
      {
        title: 'RESCHEDULE_FLIGHT',
        detail: 'Test description',
        requested_at: '2024-06-21T11:05:53.668189571',
        status: 'Open',
        id: '012',
      },
    ];
  }

  changeViewMode(mode: string) {
    this.currentModeView = mode;
    // Re init data when mode changes
    const index = this.currentModeView === 'DEPART' ? 0 : 1;
    const itinerary = this.flightDetail.flight_info?.itineraries[index];
    if (itinerary) {
      this.currentSegments = itinerary.segments;
      this.currentSegmentType = itinerary.segment_type;
      this.currentFareRule = itinerary.fare_rule;
      this.fareRuleVi = itinerary.fare_rule_vi;
      this.resource = itinerary.resource
    }
  }

  closeViewOriginalFlight() {
    this.isShowViewDetails = false;
    this.isShowViewDetailsChange.emit(this.isShowViewDetails);
  }

  formatTime(timeString: string): string {
    const timeParts = timeString.split(':');
    const date = new Date();
    date.setHours(Number(timeParts[0]));
    date.setMinutes(Number(timeParts[1]));

    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  formatDuration(timeString: string, space: boolean): string {
    const timeParts = timeString.split(':');
    const hours = Number(timeParts[0]);
    const minutes = Number(timeParts[1]);

    let formattedDuration = '';

    if (hours > 0) {
      if (space) {
        formattedDuration += `${hours}h `;
      } else {
        formattedDuration += `${hours}h`;
      }
    }

    formattedDuration += `${minutes}m`;

    return formattedDuration;
  }

  formatBaggages(num: any, quality: number, type: any): string {
    if (
      quality === 0 &&
      (type === 'K' || type === 'kg' || type == 'kilograms' || type === 'P')
    ) {
      return '';
    }

    switch (type) {
      case 'N':
        return `${num} ${this.translateService.instant('COMMON.PKG')}`;
      case 'K':
      case 'kg':
      case 'kilograms':
        return `${num}x${quality} kg`;
      case 'P':
        return `${num}x${quality} lb`;
      case 'pc(s)':
        return `${num} ${this.translateService.instant('COMMON.PCS')}`;
      default:
        if (quality && quality > 0) return `${num}x${quality} ${type}`;
        return `${num} ${type}`;
    }
  }
}
